
import { clientApi } from '@bd/api'
import { ClientOfferRowDto } from '@bd/api/client-api/types'
import DashboardFilters from '@bd/client/components/Dashboard/DashboardFilters.vue'
import { useAppStore } from '@bd/client/store'
import {
  DashboardCard,
  determinePageSize,
  FiltersChip,
  InfiniteScroll,
  Loader,
  PageContent,
  useComputedActiveFilters,
} from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { useUserData } from '@bd/components/use'
import { ChildrenData } from '@bd/store-modules/types'
import { default as isEmptyObj } from 'lodash.isempty'
import { computed, defineComponent, ref, shallowRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DashboardSearch from '@bd/client/components/Dashboard/DashboardSearch.vue'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  name: 'Dashboard',
  components: {
    DashboardFilters,
    DashboardCard,
    FiltersChip,
    Loader,
    PageContent,
    EmptyState,
    DashboardSearch,
    InfiniteScroll: InfiniteScroll.InfiniteScroll,
  },
  setup() {
    const visible = ref(false)
    const showAppointmentModal = ref(false)
    const showLoginModal = ref(false)
    const i18n = useI18n()
    const store = useAppStore()
    const selectedOfferId = ref<number | null>(null)
    const { isLogged } = useUserData()

    const citiesAndProvinces = computed(() => {
      return store.state.offers?.citiesAndProvinces.content?.content || []
    })

    const districts = computed(() => store.state.offers?.districts)

    const activeFilters = computed(() =>
      useComputedActiveFilters(store.state.offers?.searchedFilters || {}),
    )

    const showSearchedResults = computed(() => {
      return !isEmptyObj(store.state.offers?.searchedFilters)
    })

    const showSearchInputResults = computed(() => {
      return store.state.offers?.searchedFilters.address || false
    })

    const findCity = computed(() => {
      const found = activeFilters.value.find((x) => x.filterName === 'city')

      if (!found) {
        return ''
      }
      const firstChild = found.children[0].value
      const arrOfIds = Array.isArray(firstChild)
        ? firstChild.map((x) => +x)
        : []
      return citiesAndProvinces.value
        .filter((x) => x.cityId && arrOfIds.includes(x.cityId))
        .map((x) => x.cityName)
        .join(', ')
    })

    const openAppointmentModal = async (_: Event, offerId: number) => {
      if (isLogged.value) {
        store.dispatch('dialogVisibility', {
          appointmentModal: { offerId },
        })
      } else {
        store.dispatch('dialogVisibility', {
          dashboardLoginModal: { reason: 'appointment' },
        })
      }
    }

    const clearFilter = (childrenArray: ChildrenData[]) => {
      childrenArray.forEach((x) => {
        const value =
          x.name === 'cityIds' || x.name === 'districtIds' ? x.value[0] : []
        store.dispatch('offers/setFilterAndRefresh', { [x.name]: value })
      })
    }

    const isLoading = shallowRef(false)
    const totalCount = shallowRef(0)

    // not reactive on purpose. InfiniteScroll component has internal logic that
    // adjusts number of pages fetched in parallel depending on container size.
    const pageSize = determinePageSize()

    const getOffersPage = computed<InfiniteScroll.GetPageFn>(() => {
      const filters = store.state.offers?.searchedFilters || {}
      return async (page) => {
        const { data } = await clientApi.offers({ ...filters, ...page })
        return data
      }
    })

    const getOfferId = (offer: ClientOfferRowDto) => {
      return offer.id
    }

    const selectedCities = computed(() => {
      const cityFilter = activeFilters.value.find(
        ({ filterName }) => filterName === 'city',
      )
      return cityFilter?.passedData[0].value
    })

    const searchDistricts = async (cityId: number) => {
      await store.dispatch('offers/cityDistricts', cityId)
    }

    watch(selectedCities, (value) => {
      if (value && value.length === 1) {
        searchDistricts(+value[0])
      }
    })

    const recoverScrollState = () => {
      return store.state.offers?.scrollState
    }

    const storeScrollState = (state: unknown) => {
      store.commit('offers/SET_SCROLL_STATE', state)
    }

    const isSearchExpanded = ref(false)
    const isMenuExpanded = ref(false)

    const switchSearch = () => {
      isMenuExpanded.value && (isMenuExpanded.value = false)
      isSearchExpanded.value = !isSearchExpanded.value
    }

    const switchMenu = () => {
      isSearchExpanded.value && (isSearchExpanded.value = false)
      isMenuExpanded.value = !isMenuExpanded.value
    }

    return {
      t: i18n.t,
      activeFilters,
      citiesAndProvinces,
      open,
      showSearchedResults,
      isLoading,
      pageSize,
      totalCount,
      getOffersPage,
      getOfferId,
      recoverScrollState,
      storeScrollState,
      findCity,
      visible,
      showAppointmentModal,
      showLoginModal,
      openAppointmentModal,
      selectedOfferId,
      clearFilter,
      showSearchInputResults,
      districts,
      isLogged,
      isSearchExpanded,
      isMenuExpanded,
      switchSearch,
      switchMenu,
      domainURL,
    }
  },
})
