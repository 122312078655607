
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import DashboardDropdown from './DashboardDropdown/DashboardDropdown.vue'
import { useAppStore } from '@bd/client/store'
import {
  useComputedActiveFilters,
  FiltersDialog,
  useComputedFilters,
} from '@bd/components'
import { FilterProp } from '@bd/client/types'

export default defineComponent({
  name: 'DashboardFilters',
  emits: ['clear-filter', 'filter-results'],
  components: {
    DashboardDropdown,
    FiltersDialog,
  },
  setup(_, { emit }) {
    const i18n = useI18n()
    const store = useAppStore()
    const stateFilters = computed(() => store.state?.offers?.filters)
    const districts = computed(() => store.state.offers?.districts)
    const { filters } = useComputedFilters(stateFilters, districts)

    const filtersActive = computed(() =>
      useComputedActiveFilters(store.state.offers?.searchedFilters || {}),
    )
    const citiesAndProvinces = computed(
      () => store.state.offers?.citiesAndProvinces.content?.content || [],
    )

    const filtersState = computed(() => {
      return store.state.offers?.filters
    })

    const isFiltersDialogVisible = computed({
      get: () => store.state.dialog.dashboardFilters,
      set: (value: boolean | undefined) => {
        if (value !== isFiltersDialogVisible.value) {
          store.dispatch('dialogVisibility', {
            dashboardFilters: value,
          })
        }
      },
    })

    const filterResults = () => {
      if (store.state.offers?.searchedFilters !== filtersState.value) {
        store.commit('offers/APPLY_FILTERS')
      }
      isFiltersDialogVisible.value = false
      emit('filter-results')
    }

    const addToQueue = (queueObj: FilterProp) => {
      if (!queueObj?.prop) {
        return
      }
      const { prop, value } = queueObj
      store.dispatch('offers/setFilters', {
        [prop]: value,
        pageIndex: 0,
      })
      if (prop.includes('cityIds')) {
        store.dispatch('offers/cityDistricts', value)
      }
    }

    return {
      ...i18n,
      filters,
      filtersActive,
      isFiltersDialogVisible,
      filterResults,
      addToQueue,
      citiesAndProvinces,
      filtersState,
      districts,
    }
  },
})
