<template>
  <PageContent>
    <template #mobile-header>
      <div class="navbar-hero">
        <button
          class="navbar-toggler pointer"
          @click="switchMenu"
          :class="{ 'navbar-toggler-expanded': isMenuExpanded }"
        >
          <span class="navbar-toggler-icon">
            <span></span>
          </span>
        </button>
        <div>
          <a class="mr-3 link-new" :href="`${domainURL}biuro/`">
            <img src="@bd/assets/images/immoby-horizontal-logo.svg" />
          </a>
        </div>
        <div @click="switchSearch" class="navbar-hero-search pointer">
          <img src="@bd/assets/icons/search.svg" class="w-100" />
        </div>
      </div>
      <div
        class="search-wrapper"
        :class="{ 'search-wrapper-collapse': isSearchExpanded }"
      >
        <DashboardSearch @onSearch="switchSearch" />
      </div>
      <div
        class="navbar-wrapper"
        :class="{ 'navbar-wrapper-collapse': isMenuExpanded }"
      >
        <div class="inner-links">
          <a class="mr-3 link-new" :href="`${domainURL}oferty/`">{{
            t('buy')
          }}</a>
          <a class="link-new" :href="`${domainURL}aplikacja/`">
            {{ t('sell') }}
          </a>
        </div>
        <div class="auth-buttons" v-if="!isLogged">
          <Button
            :label="t('login')"
            class="p-button-tertiary p-button-rounded text-600 w-100"
            @click="$router.push({ name: 'Login' })"
          />

          <Button
            :label="t('create_account')"
            class="p-button-primary p-button-rounded text-600 w-100 register-btn"
            @click="$router.push({ name: 'Registration' })"
          />
        </div>
      </div>
    </template>

    <div class="dashboard-wrapper">
      <div>
        <div class="filters-dboard">
          <DashboardFilters
            @clear-filter="clearFilter"
            @filter-results="switchSearch"
          />
        </div>
        <div class="filters-row">
          <FiltersChip
            :activeFilters="activeFilters"
            :citiesAndProvinces="citiesAndProvinces"
            :districts="districts"
            @clear-filter="clearFilter"
          />
        </div>
      </div>
      <div class="container-fluid dashboard-padding offers-content">
        <Loader :isLoading="isLoading" />
        <p class="results-text text-600 my-4">
          <span class="c-primary">
            {{ totalCount }}
          </span>
          {{ t('results_found') }}

          <template v-if="findCity || showSearchInputResults">
            {{ t('for') + ' ' }}
          </template>

          <span class="c-primary">
            {{ findCity }}
          </span>

          <span class="c-primary">
            {{ showSearchInputResults || '' }}
          </span>
        </p>

        <InfiniteScroll
          class="card-grid"
          :pageSize="pageSize"
          :getPage="getOffersPage"
          :getItemKey="getOfferId"
          :storeState="storeScrollState"
          :recoverState="recoverScrollState"
          @is-loading="isLoading = $event"
          @total-count="totalCount = $event"
          scrollElem=".dashboard-wrapper"
        >
          <template #empty>
            <EmptyState
              class="empty-state-dashboard"
              :title="t('offers.emptyState.title')"
              :description="t('offers.emptyState.description')"
            />
          </template>

          <template #default="{ item }">
            <DashboardCard
              :displayed-icon-details="['rooms', 'area', 'floor']"
              isReversedAddress
              displayBuildYear
              :item="item"
              footer-button
              @card-click="
                $router.push({
                  name: 'DashboardOffer',
                  params: { id: item.id },
                })
              "
              @card-button-click="openAppointmentModal($event, item.id)"
            />
          </template>
        </InfiniteScroll>
      </div>
    </div>
  </PageContent>
</template>

<script lang="ts">
import { clientApi } from '@bd/api'
import { ClientOfferRowDto } from '@bd/api/client-api/types'
import DashboardFilters from '@bd/client/components/Dashboard/DashboardFilters.vue'
import { useAppStore } from '@bd/client/store'
import {
  DashboardCard,
  determinePageSize,
  FiltersChip,
  InfiniteScroll,
  Loader,
  PageContent,
  useComputedActiveFilters,
} from '@bd/components'
import EmptyState from '@bd/components/EmptyState/EmptyState.vue'
import { useUserData } from '@bd/components/use'
import { ChildrenData } from '@bd/store-modules/types'
import { default as isEmptyObj } from 'lodash.isempty'
import { computed, defineComponent, ref, shallowRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import DashboardSearch from '@bd/client/components/Dashboard/DashboardSearch.vue'

const domainURL = process.env.VUE_APP_DOMAIN_URL

export default defineComponent({
  name: 'Dashboard',
  components: {
    DashboardFilters,
    DashboardCard,
    FiltersChip,
    Loader,
    PageContent,
    EmptyState,
    DashboardSearch,
    InfiniteScroll: InfiniteScroll.InfiniteScroll,
  },
  setup() {
    const visible = ref(false)
    const showAppointmentModal = ref(false)
    const showLoginModal = ref(false)
    const i18n = useI18n()
    const store = useAppStore()
    const selectedOfferId = ref<number | null>(null)
    const { isLogged } = useUserData()

    const citiesAndProvinces = computed(() => {
      return store.state.offers?.citiesAndProvinces.content?.content || []
    })

    const districts = computed(() => store.state.offers?.districts)

    const activeFilters = computed(() =>
      useComputedActiveFilters(store.state.offers?.searchedFilters || {}),
    )

    const showSearchedResults = computed(() => {
      return !isEmptyObj(store.state.offers?.searchedFilters)
    })

    const showSearchInputResults = computed(() => {
      return store.state.offers?.searchedFilters.address || false
    })

    const findCity = computed(() => {
      const found = activeFilters.value.find((x) => x.filterName === 'city')

      if (!found) {
        return ''
      }
      const firstChild = found.children[0].value
      const arrOfIds = Array.isArray(firstChild)
        ? firstChild.map((x) => +x)
        : []
      return citiesAndProvinces.value
        .filter((x) => x.cityId && arrOfIds.includes(x.cityId))
        .map((x) => x.cityName)
        .join(', ')
    })

    const openAppointmentModal = async (_: Event, offerId: number) => {
      if (isLogged.value) {
        store.dispatch('dialogVisibility', {
          appointmentModal: { offerId },
        })
      } else {
        store.dispatch('dialogVisibility', {
          dashboardLoginModal: { reason: 'appointment' },
        })
      }
    }

    const clearFilter = (childrenArray: ChildrenData[]) => {
      childrenArray.forEach((x) => {
        const value =
          x.name === 'cityIds' || x.name === 'districtIds' ? x.value[0] : []
        store.dispatch('offers/setFilterAndRefresh', { [x.name]: value })
      })
    }

    const isLoading = shallowRef(false)
    const totalCount = shallowRef(0)

    // not reactive on purpose. InfiniteScroll component has internal logic that
    // adjusts number of pages fetched in parallel depending on container size.
    const pageSize = determinePageSize()

    const getOffersPage = computed<InfiniteScroll.GetPageFn>(() => {
      const filters = store.state.offers?.searchedFilters || {}
      return async (page) => {
        const { data } = await clientApi.offers({ ...filters, ...page })
        return data
      }
    })

    const getOfferId = (offer: ClientOfferRowDto) => {
      return offer.id
    }

    const selectedCities = computed(() => {
      const cityFilter = activeFilters.value.find(
        ({ filterName }) => filterName === 'city',
      )
      return cityFilter?.passedData[0].value
    })

    const searchDistricts = async (cityId: number) => {
      await store.dispatch('offers/cityDistricts', cityId)
    }

    watch(selectedCities, (value) => {
      if (value && value.length === 1) {
        searchDistricts(+value[0])
      }
    })

    const recoverScrollState = () => {
      return store.state.offers?.scrollState
    }

    const storeScrollState = (state: unknown) => {
      store.commit('offers/SET_SCROLL_STATE', state)
    }

    const isSearchExpanded = ref(false)
    const isMenuExpanded = ref(false)

    const switchSearch = () => {
      isMenuExpanded.value && (isMenuExpanded.value = false)
      isSearchExpanded.value = !isSearchExpanded.value
    }

    const switchMenu = () => {
      isSearchExpanded.value && (isSearchExpanded.value = false)
      isMenuExpanded.value = !isMenuExpanded.value
    }

    return {
      t: i18n.t,
      activeFilters,
      citiesAndProvinces,
      open,
      showSearchedResults,
      isLoading,
      pageSize,
      totalCount,
      getOffersPage,
      getOfferId,
      recoverScrollState,
      storeScrollState,
      findCity,
      visible,
      showAppointmentModal,
      showLoginModal,
      openAppointmentModal,
      selectedOfferId,
      clearFilter,
      showSearchInputResults,
      districts,
      isLogged,
      isSearchExpanded,
      isMenuExpanded,
      switchSearch,
      switchMenu,
      domainURL,
    }
  },
})
</script>

<style lang="scss" scoped>
$unauthorized-buttons-height: 75px;
@include breakpoint-down(md) {
  .dashboard-padding {
    padding: 0 20px;
  }
}

@include breakpoint-up(md) {
  .dashboard-padding {
    padding: 0 60px;
  }
  .gutter-2.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-2 > [class^='col-'],
  .gutter-2 > [class^=' col-'] {
    padding-right: 30px;
    padding-left: 30px;
  }
}

.results-text {
  font-size: 22px;
}

.filters-dboard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include breakpoint-up(md) {
    padding: 12px 60px;
    background: $alabaster;
  }
}

.card-grid {
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));

  @include breakpoint-down(md) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

$custom-header-height: 70px;

.mobile-header {
  display: flex;
  align-items: center;
  height: $custom-header-height;
}

@include breakpoint-down(md) {
  :deep(.page-content) {
    height: calc(100vh - #{$custom-header-height});

    &.bottom-nav-visible {
      height: calc(
        100vh - #{$custom-header-height} - #{$mobile-navigation-height}
      );

      .loader-overlay,
      .loader {
        bottom: #{$mobile-navigation-height} !important;
      }
    }

    .loader-overlay,
    .loader {
      bottom: #{$unauthorized-buttons-height} !important;
    }
  }
}

.dashboard-wrapper {
  flex: 1;
  overflow: auto;
}

.empty-state-dashboard {
  height: 80vh;
  grid-column: 1 / -1;
}

.filters-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.unauthorized-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: $white;
  @include breakpoint-up(md) {
    display: none;
  }
  @include flex;
  height: $unauthorized-buttons-height;
  padding: 0.625rem;
  .p-button {
    font-size: 0.875rem;
    flex: 1;
    margin: 0.25rem;
  }
}

.navbar-hero {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 20px;
  height: $custom-header-height;
  &-search {
    width: 24px;
  }

  @include breakpoint-up(md) {
    display: none;
  }
}

.search-wrapper {
  position: absolute;
  width: 100%;
  height: 0;
  transition: all 0.4s;
  overflow: hidden;
  padding: 0 20px;
  z-index: 1;
  background: $white;

  &-collapse {
    height: 70px;
  }

  @include breakpoint-up(md) {
    display: none;
  }
}

.navbar-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  z-index: 1;
  padding: 0px 20px;
  transition: all 0.4s;
  background: $white;

  &-collapse {
    height: calc(100% - #{$custom-header-height});
    opacity: 1;
    padding: 0px 20px 10px;
  }

  @include breakpoint-up(md) {
    display: none;
  }
}

.inner-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
  height: 100%;
  a {
    padding: 0.5rem 1rem;
  }
}

.auth-buttons {
  display: flex;
  width: 100%;
  flex: 1;
}

.navbar-menu-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 100%;
}

.register-btn {
  margin-left: 10px;
}

.navbar-toggler {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  border: 0 solid transparent;
  border-radius: 0;
  &:focus {
    outline: 0;
  }
}
.navbar-toggler-icon {
  position: relative;
  width: 28px;
  margin: 0 auto;
  background-color: $black;
  transition: background-color 0.2s 0.2s ease;
}
.navbar-toggler-icon,
.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
  display: block;
  height: 2px;
  background-clip: padding-box;
}
.navbar-toggler-icon:before,
.navbar-toggler-icon:after {
  position: absolute;
  content: '';
  width: 28px;
  background-color: $black;
  transform-origin: 50% 50%;
  transition: background-color 0.2s 0.2s ease, top 0.2s 0.4s ease,
    left 0.2s 0.2s ease, right 0.2s 0.2s ease, width 0.2s 0.2s ease,
    transform 0.2s ease;
}
.navbar-toggler-icon:before {
  top: 7px;
  left: 0;
}
.navbar-toggler-icon:after {
  top: -7px;
  right: 0;
}
.navbar-toggler-expanded .navbar-toggler-icon {
  background-color: transparent;
  transition: background-color 0.2s 0s ease;
}
.navbar-toggler-expanded .navbar-toggler-icon:before,
.navbar-toggler-expanded .navbar-toggler-icon:after {
  top: 0;
  width: 28px;
  transition: top 0.2s ease, left 0.2s 0.2s ease, right 0.2s 0.2s ease,
    width 0.2s 0.2s ease, transform 0.2s 0.4s ease;
}
.navbar-toggler-expanded .navbar-toggler-icon:before {
  left: 0;
  transform: rotate3d(0, 0, 1, 45deg);
}
.navbar-toggler-expanded .navbar-toggler-icon:after {
  right: 0;
  transform: rotate3d(0, 0, 1, -45deg);
}
</style>
